import Vue from 'vue'
import VueRouter from 'vue-router'
import Timeline from '../views/Timeline'
import Timeline2 from '../views/Timeline2'
import qrcode from '../views/qrcode'
import Index from '../views/index'
import Publicity from '../views/publicity'
const Profile = () => import('../views/profile')


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Publicity',
    component: Publicity
  },
  {
    path: '/index',
    name: 'Index',
    component: Index
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile

  },
  {
    path: '/timeline',
    component: Timeline,
  },
  {
    path: '/timeline2',
    component: Timeline2,
  },
  {
    path: '/qrcode',
    component: qrcode,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
